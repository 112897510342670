import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "vanguard",
  casinoId: 40,
  GA_TRACKING:'G-X5WLG6G8VS',

  rivalChatGroupName: "Vanguard Casino",
  prettyName: "Vanguards Casino",
  contactEmail: "support@vanguardscasino.com",
  docsEmail: "documents@vanguardscasino.email",

  assetsPath: "assets/brands/vanguards",

  publicPromotions: [
    {id: 'PARADISE8_1', name: "deposit", state: "eligible", details: "welcome", imgPath: "assets/brands/vanguards/promo.png"},
    {id: 2, name: "cashbackInsurance", state: "eligible", details: "welcome", imgPath: "assets/brands/vanguards/promo.png"},
    {id: 'PARADISE8_3', name: "nextDayCashback", state: "eligible", details: "weekly", imgPath: "assets/brands/vanguards/promo.png"},
    {id: 'PARADISE8_4', name: "loyalty", state: "eligible", details: "weekly", imgPath: "assets/brands/vanguards/promo.png"}
  ],

  //SEO
  metaDescription: "Play Vanguards Casino a leading Online Gaming platform with 500+ premium slots, live dealer games, and classic table games like blackjack, roulette, and baccarat. Get no deposit bonus, free spins and welcome bonuses. Make deposit even in crypto and take immediate and safe payouts nsow!",
  logoName: "vanguards-casino-online-casino-logo.png",
  logoAltText: "Vanguards Casino online logo with bold gold and white text and shield icon known for secure slots table games and exclusive casino rewards."
};


